import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login';import PrivateRoute from './components/routing';
import ResponsiveAppBar from './components/navigation';
import QAndA from './pages/qanda';
import Locations from './pages/locations';
import Location from './pages/location';
import Home from './pages/home';
import { BookingCalendar } from './pages/calendar';
import { AddLocation } from './pages/add-location';

const App = () => {

  useEffect(() => {console.log(process.env.REACT_APP_DB_PUBLIC_KEY, process.env.REACT_APP_DB_URL)})

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/' element={<PrivateRoute><ResponsiveAppBar /></PrivateRoute>}>
          <Route path='/' index element={<Home />} />
          <Route path={'q_and_a'} element={<QAndA />} />
          <Route path={'locations'} element={<Locations />} />
          <Route path={'location/:id'} element={<Location />} />
          <Route path={'calendar'} element={<BookingCalendar />} />
          <Route path={'add-location'} element={<AddLocation />} />
        </Route>
        <Route path='*' element={<Navigate to='/login' />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;