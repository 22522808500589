import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Box, Button, Chip, Grid, Link, Paper, Typography } from "@mui/material";
import { supabase } from "../database";
import { useNavigate } from "react-router-dom";

/**
 * This code manually sets the leaflet map marker images.
 */
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

/**
 * This component is resonsible for showing locations of nice events.
 * @returns 
 */
function Locations() {

    const navigate = useNavigate();

    // Location format: title, description, longitude, latitude, link, bewertung (1-5), date
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        getLocations();
    }, []);

    async function getLocations() {
        const { data } = await supabase
            .from('Locations')
            .select(`
            *,
            Categories ( id, name )
            `)
        setLocations(data)
    }

    return (
    <Box sx={{height: '100%'}}>
        <MapContainer center={[40.139308, 9.093221]} zoom={7} scrollWheelZoom={false} style={{ height: "250px", width: "100%" }}>
            <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
                locations.map((location) => (
                    <Marker position={[location.latitude, location.longitude]} key={location.id}>
                        <Popup>{location.title}</Popup>
                    </Marker>
                ))
            }
        </MapContainer>
        <Box sx={{height: '10px'}} />
        <Button variant="outlined" fullWidth onClick={() => navigate('/add-location')}>Add Location</Button>
        <Box sx={{height: '10px'}} />
        <Box sx={{overflow: 'scroll', pd: 1, pb: 1}}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {
                    locations.map((location) => (
                        <Grid item 
                        xs={6}
                        key={location.id}
                        >
                            <Paper
                            elevation={1}
                            sx={{
                                padding: 1,
                                boxSizing: "border-box",
                                borderRadius: 2
                            }}
                            >
                                <Typography 
                                variant='h5'
                                component={Link}
                                onClick={() => {navigate(`/location/${location.id}`)}}
                                sx={[
                                    {
                                        textDecorationColor: 'green',
                                        color: 'black'
                                    },
                                    {
                                        '&:hover': {
                                            cursor: 'pointer',
                                            color: '#3655b3',
                                            fontWeight: 500
                                        }
                                    }
                                ]}>
                                    {location.title}
                                </Typography>
                                <Box sx={{mt: 1}}>
                                    {location.Categories?.map((categorie, index) => {
                                        return <Chip variant="outlined" key={index} label={categorie.name} sx={{mr: 1, backgroundColor: '#1876d2', color: 'white'}}/>
                                    })}
                                </Box>
                            </Paper>
                        </Grid>
                    ))
                }
            </Grid>
        </Box>
    </Box>
    );
}

export default Locations;