import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { isLoggedIn } from "../database";

/**
 * This component requests the session object from the current supabase instance and redirects to /login no session is active.
 * @param {*} children: [Component] 
 * @returns True - Session is established | False - Session is null
 */
const PrivateRoute = ({children}) => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [checkingLogin, setCheckingLogin] = useState(true);

    useEffect(() => {
        isLoggedIn().then((isLoggedIn) => {
            setLoggedIn(isLoggedIn);
            setCheckingLogin(false);
        }).catch((error) => {
            alert("Retrieving session failed...");
            console.error(error);
            setCheckingLogin(false);
        });
    }, []);

    if (checkingLogin) {
        return <div>Loading...</div>;
    }

    return loggedIn ? children : <Navigate to={'/login'} />;
}

export default PrivateRoute;