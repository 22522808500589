import { Box, Button, Chip, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../database";

function Location() {

    const navigate = useNavigate()

    const [location, setLocation] = useState({});

    const [description, setDescription] = useState([]);

    let { id } = useParams()

    useEffect(() => {
        async function setLocationById() {
            const { data, error } = await supabase
                .from('Locations')
                .select(`
                *,
                Categories ( id, name )
                `)
                .eq('id', id)
                .single();
            if (error) {
                console.error(error);
                setLocation(null)
                setDescription([])
            }
            setLocation(data)
            setDescription(await loadPreview(JSON.parse(data.description)))
        }
        setLocationById();
    }, [id])

    async function loadPreview(description) {
        const contentPromises = description.map(async (data, index) => {
            if (data.type === "img") {
                try {
                    const { data: imgData, error: imgError } = await supabase.storage.from("img").download(data.content);
                    if (imgError) throw new Error('Error downloading image: ' + imgError.message);
                    const imgUrl = URL.createObjectURL(imgData);
                    return <img key={index} style={{ maxWidth: "100%" }} src={imgUrl} alt="Loaded from Supabase" />;
                } catch (error) {
                    console.error(error);
                    return <></>;
                }
            } else if (data.type === "p") {
                return <Typography key={index} sx={{maxWidth: '100%', wordWrap: 'break-word'}}>{data.content}</Typography>;
            } else {
                return <></>;
            }
        });    
        const descriptionPreview = await Promise.all(contentPromises);
        return descriptionPreview;
    }

    return (
        <Container>
            <Typography variant="h3">{location.title}</Typography>
            <Typography variant="h6">Created At: {location.created_at}</Typography>
            <Typography variant="h6">Latitude: {location.latitude}</Typography>
            <Typography variant="h6">Longitude: {location.longitude}</Typography>
            <Box>
                <Typography variant="h6">Categories</Typography>
                {location.Categories?.map((categorie, index) => {
                    return <Chip variant="outlined" key={index} label={categorie.name} sx={{mr: 1, backgroundColor: '#1876d2', color: 'white'}}/>
                })}
            </Box>
            <Typography variant="h6">Description:</Typography>
                {description}
            <Box sx={{height: '10px'}}/>
            <Button
            onClick={() => {navigate('/locations')}}
            variant={'contained'}
            fullWidth
            >
                BACK
            </Button>
        </Container>
    );
}

export default Location;