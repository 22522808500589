import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
`;


export const FormContainer = styled.div`
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 100%;
  max-width: 400px;
`;

export const FormTitle = styled.h2`
  color: #333;
  text-align: center;
  margin-bottom: 24px;
`;

export const PasswordInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;
