import React, { useState } from 'react';
import { PageContainer, FormContainer, FormTitle, PasswordInput, SubmitButton, ErrorMessage } from '../components/login';
import { supabase } from '../database'
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

const LoginPage = () => {

  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  async function signInWithEmail() {
    const { error } = await supabase.auth.signInWithPassword({
        email: 'default@sardinien.emilschwenger.com',
        password: password,
    })
    if (error) {
        setError('Invalid password');
    } else {
        setError('');
        navigate('/')
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    signInWithEmail();
  };

  return (
    <PageContainer>
      <FormContainer>
        <FormTitle>Sardinien Home Login</FormTitle>
        <Typography sx={{color: 'red', textAlign: 'center', marginBottom: '20px'}} component='p' çvariant='h7'>This website is exclusively for the use of family and friends of the website owner.</Typography>
        <form onSubmit={handleSubmit}>
          <PasswordInput
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <SubmitButton type="submit">Login</SubmitButton>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </form>
      </FormContainer>
    </PageContainer>
  );
};

export default LoginPage;
