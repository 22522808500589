import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { supabase } from "../database";

function QAndA() {

    const [questionContent, setQuestionContent] = useState([])

    useEffect(() => {
        getQuestions();
    }, []);

    async function getQuestions() {
        const { data } = await supabase.from("QandA").select();
        let questionContentTmp = []
        for (let i = 0, len = data.length; i < len; i++) {
            questionContentTmp.push(await parseQuestion(data[i]))
        }
        setQuestionContent(questionContentTmp)
    }

    async function parseQuestion(questionData) {
        let questionContent = JSON.parse(questionData.answer);

        let questionParsed = {};
        questionParsed.question = questionData.question;
        const contentPromises = questionContent.map(async (data, index) => {
            if (data.type === "img") {
                try {
                    const { data: imgData, error: imgError } = await supabase.storage.from("img").download(data.content);
                    if (imgError) throw new Error('Error downloading image: ' + imgError.message);
                    const imgUrl = URL.createObjectURL(imgData);
                    console.log(imgUrl)
                    return <img key={index} style={{ maxWidth: "100%" }} src={imgUrl} alt="Loaded from Supabase" />;
                } catch (error) {
                    console.error(error);
                    return <></>;
                }
            } else if (data.type === "p") {
                return <Typography key={index} sx={{maxWidth: '100%', wordWrap: 'break-word'}}>{data.content}</Typography>;
            } else {
                return <></>;
            }
        });    
        questionParsed.content = await Promise.all(contentPromises);

        return questionParsed;
    }
    

    return (
        <>
            {questionContent.map((question, index) => (
                <Accordion
                key={index}
                >
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    key={index}
                    >
                        {question.question}
                    </AccordionSummary>
                    <AccordionDetails key={index}>
                        {question.content}
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
}

export default QAndA;