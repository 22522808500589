import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  process.env.REACT_APP_DB_PUBLIC_KEY,
  process.env.REACT_APP_DB_URL
  );

    /**
   * 
   * @returns Promise[boolean] A promise that contains a boolean if the session onject is existent or not.
   */
async function isLoggedIn() {
  const session = await supabase.auth.getSession();
  return !(session.data.session === null);
}

export { supabase, isLoggedIn };