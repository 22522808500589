import { Autocomplete, Box, Button, Chip, Divider, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { supabase } from "../database";
import { useNavigate } from "react-router-dom";
import MultiSelectCategoryAutocomplete from "../components/mutliselect-categories";

export function AddLocation() {

    const navigate = useNavigate();

    const [uploading, setUploading] = useState(false);
    const [fileList, setFileList] = useState([]);
    
    const [locationTitle, setLocationTitle] = useState("");
    const [locationLongitude, setLocationLongitude] = useState(0);
    const [locationLatitude, setLocationLatitude] = useState(0);
    const [locationDescription, setLocationDescription] = useState([]);
    const [nodeLocationDescription, setNodeLocationDescription] = useState(null);

    const [newParagraphValue, setNewParagraphValue] = useState('');
    const [newImageValue, setNewImageValue] = useState('')

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [allCategories, setAllCategories] = useState([]);

    // Function for filtering options
    function filter(options, { inputValue }) {
        return options.filter((option) => option.title.toLowerCase().includes(inputValue.toLowerCase()));
    }

    // Fetch categories and update state variable
    async function fetchCategories() {
        const {data} = await supabase.from('Categories').select();
        if (data) {
        setAllCategories(data.map((category, index) => {
            return {
            title: category.name,
            id: index
            }
        }));
        }
    }
    useEffect(() => {
        fetchCategories();
    }, []);

    // Insert a new category into the database
    async function insertNewCategory(newCategoryName) {
        await supabase.from('Categories').insert({
        name: newCategoryName
        });
        fetchCategories();
    }

    // Handle adding a new category to the location
    const handleAddCategory = (event, newValue, value) => {
        if (value === 'removeOption' || value === 'selectOption') {
        setSelectedCategories(newValue);
        } else if (value === 'createOption') {
        let added_category = {
            title: newValue[newValue.length - 1],
            id: selectedCategories.length
        }
        setSelectedCategories([...selectedCategories, added_category])
        insertNewCategory(added_category.title);
        }
    };

    // edit description content
    const addImageToDescription = () => {
        if (newImageValue) {
            setLocationDescription([...locationDescription, {
                type: 'img',
                content: newImageValue.label
            }])
        }
    }
    const addParagraphToDescription = () => {
        if (newParagraphValue) {
            setLocationDescription([...locationDescription, {
                type: 'p',
                content: newParagraphValue
            }])
        }
    }
    useEffect(() => {
        updatePreview();
    }, [locationDescription])

    // preview functionality
    async function updatePreview() {
        const loadedPreview = await loadPreview();
        setNodeLocationDescription(loadedPreview);
    }

    async function loadPreview() {
        const contentPromises = locationDescription.map(async (data, index) => {
            if (data.type === "img") {
                try {
                    const { data: imgData, error: imgError } = await supabase.storage.from("img").download(data.content);
                    if (imgError) throw new Error('Error downloading image: ' + imgError.message);
                    const imgUrl = URL.createObjectURL(imgData);
                    return <img key={index} style={{ maxWidth: "100%" }} src={imgUrl} alt="Loaded from Supabase" />;
                } catch (error) {
                    console.error(error);
                    return <></>;
                }
            } else if (data.type === "p") {
                return <Typography key={index} sx={{maxWidth: '100%', wordWrap: 'break-word'}}>{data.content}</Typography>;
            } else {
                return <></>;
            }
        });    
        const descriptionPreview = await Promise.all(contentPromises);
        return descriptionPreview;
    }

    const insertLocation = async () => {
        if (locationTitle && locationLatitude && locationLongitude && locationDescription) {
            const newLocation = {
                title: locationTitle,
                description: locationDescription,
                latitude: locationLatitude,
                longitude: locationLongitude
            }
            const { data: locationData } = await supabase.from('Locations').insert(newLocation).select();
            if (selectedCategories.length > 0) {
                selectedCategories.forEach((category) => {
                    const retrieveCategoryAndInsertRelationship = async (locationId) => {
                        const {data: categoryData} = await supabase.from('Categories').select().eq('name', category.title)
                        await supabase.from('LocationCategories').insert({
                            location_id: locationId,
                            category_id: categoryData[0].id
                        })
                    }
                    retrieveCategoryAndInsertRelationship(locationData[0].id);
                });
            }
        }
    }

    const fetchFileNames = async () => {
        const { data } = await supabase.storage
        .from('img')
        .list('', {
            sortBy: { column: 'created_at', order: 'desc' },
        })
        setFileList(data.map((file) => {
            return {
                label: file.name
            }
        }));
    }

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
        setUploading(true);
        const { data, error } = await supabase.storage
            .from('img')
            .upload(`${file.name}`, file, {
                cacheControl: '3600',
                upsert: false,
            });
        setUploading(false);
        if (error) {
            alert('Error uploading file: ' + error.message);
        } else {
            alert('File uploaded successfully.');
            fetchFileNames();
        }
    };

    useState(() => {
        fetchFileNames();
    }, [])

    return (
        <>
            <Paper
            elevation={1}
            sx={{
                borderRadius: 1,
                padding: 2,
                mb: 2
            }}
            >
                <Box 
                sx={{
                    width: '100%'
                }}
                >
                    <Typography variant="h4" sx={{mb: 2}}>Upload Image</Typography>
                    <input type="file" onChange={handleFileChange} disabled={uploading} />
                </Box>
            </Paper>
            <Paper
            elevation={1}
            sx={{
                borderRadius: 1,
                padding: 2
            }}
            >
                <Box 
                sx={{
                    width: '100%'
                }}
                >
                    <Typography variant="h4" sx={{mb: 2}}>Create Location</Typography>
                    <TextField sx={{width: '100%', mb: 2}} variant="outlined" label='Title' value={locationTitle} onChange={(event) => setLocationTitle(event.target.value)}/>
                    <TextField sx={{width: '100%', mb: 2}} variant="outlined" type="number" label='Latitude' value={locationLatitude} onChange={(event) => setLocationLatitude(event.target.value)}/>
                    <TextField sx={{width: '100%', mb: 2}} variant="outlined" type="number" label='Longitude' value={locationLongitude} onChange={(event) => setLocationLongitude(event.target.value)}/>
                </Box>
                <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
                >
                    <Divider sx={{mt: 2}}/>
                    <Typography  variant="h5" sx={{mt: 2, mb: 2}}>Description preview:</Typography>
                        {nodeLocationDescription}
                    <Divider sx={{mb: 2}}/>
                </Box>
                <Autocomplete
                multiple
                options={allCategories}
                value={selectedCategories}
                getOptionLabel={(option) => option.title}
                defaultValue={[]}
                freeSolo
                sx={{
                    mb: 2
                }}
                autoHighlight
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                    <Chip variant="outlined" label={option.title} {...getTagProps({ index })} />
                    ))
                }
                onChange={(event, newValue, value) => handleAddCategory(event, newValue, value)}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params)
                    return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderInput={(params) => (
                    <TextField {...params} label="Select or create categories" placeholder="Categories" />
                )}
                />
                <Box>
                    <Box>
                        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, mb: 2,  alignItems: 'center'}}>
                            <Autocomplete
                            disablePortal
                            options={fileList}
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="Image" variant="standard"/>}
                            onChange={(event, newValue) => setNewImageValue(newValue)}
                            />
                            <Button variant="outlined" 
                            sx={{
                                ml: {xs: 0, md: 2},
                                mt: {xs: 2, md: 0},
                                mb: {xs: 2, md: 0},
                                height: '100%',
                                width: {xs: '100%', md: 'auto'}
                            }} 
                            onClick={() => {
                                addImageToDescription();
                            }}>Append Image To Description</Button>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: {xs: 'column', md: 'row'}, mb: 2, alignItems: 'center'}}>
                            <TextField 
                            fullWidth 
                            multiline
                            variant="standard"
                            value={newParagraphValue} 
                            onChange={(event) => setNewParagraphValue(event.target.value)} 
                            label='paragraph text'
                            sx={{
                                height: '100%',
                                boxSizing: 'border-box'
                            }}
                            />
                            <Button variant="outlined" 
                            sx={{
                                ml: {xs: 0, md: 2},
                                mt: {xs: 2, md: 0},
                                mb: {xs: 2, md: 0},
                                height: '100%',
                                width: {xs: '100%', md: 'auto'}
                            }}
                            onClick={() => {
                                addParagraphToDescription();
                            }}>Append Paragraph To Description</Button>
                        </Box>
                    </Box>
                    <Button variant="contained" fullWidth onClick={() => {
                        insertLocation().then(() => {
                            navigate('/locations');
                        })
                    }}>Add Location</Button>
                </Box>
            </Paper>
        </>
    );
}