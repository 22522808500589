import React, { useEffect, useState } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { Box, Button, Divider, IconButton, List, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { supabase } from "../database";
import Calendar from 'react-calendar';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import styled from "styled-components";

export function BookingCalendar() {

    const [bookedDateRanges, setBookedDateRanges] = React.useState([]);
    const [arrivalDateField, setArrivalDateField] = useState(null);
    const [departureDateField, setDepartureDateField] = useState(null);

    // Check if a day is within any of the date ranges
    const isDayInRanges = (day) => {
        return bookedDateRanges.some(bookedDateRange => day <= bookedDateRange.to && day >= bookedDateRange.from);
    };

    // Fetch the bookings from supabase
    const fetchBookings =  async () => {
        const { data } = await supabase.from("Bookings").select();
        let tmpBookedDateRanges = []
        data.forEach((booking) => {
            tmpBookedDateRanges.push({
                from: dayjs(booking.from),
                to: dayjs(booking.to),
                id: booking.id
            });
        });
        setBookedDateRanges(tmpBookedDateRanges.reverse());
    }

    // Removes a booking from supabase and fetches bookings
    const removeBooking = async (id) => {
        await supabase.from('Bookings').delete().eq('id', id);
        fetchBookings();
    }

    // format check for arrival and departure date field
    const checkBookingTimestamps = () => {
        if (arrivalDateField && departureDateField && departureDateField >= arrivalDateField) {
            return true;
        } else {
            return false;
        }
    }

    // Inserts a booking from supabase and fetches bookings
    const insertBooking = async () => {
        const password = prompt('Please enter the admin password.')
        if (password !== 'admin') {
            alert('Wrong password!')
            return;
        }
        if (!checkBookingTimestamps()) {
            return;
        }
        const newBooking = {
            from: arrivalDateField.format('YYYY-MM-DD'),
            to: departureDateField.format('YYYY-MM-DD')
        }
        await supabase.from('Bookings').insert(newBooking);
        fetchBookings();
    }

    useEffect(() => {
        fetchBookings()
    }, []);

    return (
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyItems: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap'
        }}
        >
            <Paper elevation={1} sx={{padding: '20px', borderRadius: 1, mb: 2, flexGrow: 1}}>
                <Typography variant="h4" sx={{mb: 2}}>Calendar</Typography>
                <StyledCalendar tileContent={({ date, _ }) => (
                    <div
                    style={{
                        backgroundColor: isDayInRanges(date) ? 'red' : 'transparent',
                        color: isDayInRanges(date) ? 'white' : 'black',
                    }}
                    >
                        -
                    </div>
                )}/>
            </Paper>
            <Paper
            elevation={1}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                borderRadius: 1,
                height: '280px',
                mb: 2,
                width: {xs: '100%', md: '42%'}
            }}
            >
                <Typography variant="h4" sx={{mb: 2}}>Add stay</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DatePicker label='Arrival date' sx={{mt: 2}} value={arrivalDateField} onChange={(newValue) => {setArrivalDateField(newValue)}} />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DatePicker label='Departure date' sx={{mt: 2}} value={departureDateField} onChange={(newValue) => {setDepartureDateField(newValue)}} />
                </LocalizationProvider>
                <Button fullWidth variant="outlined" sx={{mt: 2}} onClick={() => insertBooking()}>Add</Button>
            </Paper>
            <Paper
            elevation={1}
            sx={{
                padding: '20px',
                borderRadius: 1,
                mb: 2,
                width: {xs: '100%', md: '42%'},
                height: '280px',
                ml: {xs: 0, md: 2},
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1
            }}
            >
                <Typography variant="h4" sx={{mb: 2}}>Current stays</Typography>
                <Box
                sx={{
                    flexGrow: 1,
                    overflow: 'scroll'
                }}
                >
                    <List>
                        {bookedDateRanges.map((value, index) => {
                            return (
                                <>
                                    <ListItem
                                    key={index}
                                    secondaryAction={
                                        <IconButton onClick={() => removeBooking(value.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                    >
                                        <ListItemText sx={{mr: 3}}>From: {value.from.format('DD-MM-YYYY')} To: {value.to.format('DD-MM-YYYY')}</ListItemText>
                                    </ListItem>
                                    <Divider />
                                </>
                            );
                        })}
                    </List>
                </Box>
            </Paper>
        </Box>
    );
}

const StyledCalendar = styled(Calendar)`
    width: 100%;
    height: 250px;
`;