import React from "react";
import styled from "styled-components";

function Home() {
    return (
        <>
            <h1>TODO: Homepage</h1>
            <HomeBackground src="/background.jpg" />
        </>
    );
}

const HomeBackground = styled.img`
    width: 100%;
    height: 100%;
    background-size: cover;
`;

export default Home;